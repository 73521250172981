import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './Reviews.css'

const fiveStars = (
  <div className='stars'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
    <span className="fa fa-star checked"></span>
    <span className="fa fa-star checked"></span>
    <span className="fa fa-star checked"></span>
    <span className="fa fa-star checked"></span>
    <span className="fa fa-star checked"></span>
  </div>
);

const Review = props => {
  return (
    <div className="col-lg-4">
      <div className="card border-0">
        <div className="card-body">
          {fiveStars}
          {props.text}
          <br/>
          <div className='reviewer'>
            {props.reviewer}
          </div>
        </div>
      </div>
    </div>
  )
}

const Reviews = props => {
  return (
    <div className="container reviews-container">
      <div className="row">
          <Review 
            text="This is an excellent introduction to the stock market for beginners and an excellent reminder for experts."
            reviewer='Matthew'
          />
          <Review
              text="I've been investing for a long time now and I've never seen such as simple and well explained summary on investing."
              reviewer="Jonathan"
          />
          <Review
              text="Thank you so much. I understand stocks and stock terminology so much better now!"
              reviewer="Alexy"
          />
      </div>
    </div>
  )
}

export default Reviews;