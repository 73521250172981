import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './StoreButtons.css'

const StoreButtons = props => {
  return (
    <p className="b-app__btns u-mb-0">
      <a href="https://apps.apple.com/app/apple-store/id973162352?pt=87439801&ct=LearnWebsite&mt=8" className="b-app__btn b-app__btn--apple">
        <span className="b-app__icon">
          <span className="u-vhide">
            Download on the App Store
          </span>
        </span>
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.rubicoin.invest&referrer=utm_source%3DLearnWebsite%26utm_medium%3Dweb%26utm_campaign%3DLearnWebsite" className="b-app__btn b-app__btn--google">
        <span className="b-app__icon">
          <span className="u-vhide">
            Get it On Google Play
          </span>
        </span>
      </a>
    </p>
  )
}

export default StoreButtons;