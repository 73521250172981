import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './NextUpButton.css'

const NextUpButton = props => {

  let lessonId = props.lessonId,
      sections = props.sections,
      lessonSectionMap = props.lessonSectionMap;

  let maxSectionOrder = sections[sections.length - 1].order,
      section = sections.find(section => section.id === lessonSectionMap[lessonId].sectionId),
      lessons = section.lessons;

  let maxLessonOrder = lessons[section.lessons.length - 1].order,
      lessonOrder = lessons.find(lesson => lesson.id === lessonId ).order;

  var to = '',
      text = '';

  if (lessonOrder < maxLessonOrder) {
    let nextLessonIndex = lessons.findIndex(lesson => lesson.order > lessonOrder),
        nextLesson = lessons[nextLessonIndex];
    to = `/lesson/${nextLesson.id}`;
    text = `Next Lesson: ${nextLesson.name}`;
  } else {
    if (section.order < maxSectionOrder) {
      let nextSectionIndex = sections.findIndex(otherSection => otherSection.order > section.order),
          nextSection = sections[nextSectionIndex];
      to = `/section/${nextSection.id}`;
      text = `Next Section: ${nextSection.name}`;
    } else {
      to = "/";
      text = "Go to Home Page";
    }
  }

  return (
    <div className="button-container">
      <Link to={to} onClick={(e) => {
        return props.handleClick(lessonId);
      }
      }> <button className="action-button">{text}</button></Link> 
    </div>
  )
}

export default NextUpButton;