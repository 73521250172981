import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './ContinueButton.css'

const ContinueButton = props => {
 
  var nextLessonId = -1;
  for (let section of props.sections) {
    for (let lesson of section.lessons) {
      if (!props.lessonState[lesson.id].finished) {
        nextLessonId = lesson.id;
        break;
      }
    }
    if (nextLessonId >= 0) { break; }
  }

  if (nextLessonId >= 0) {
    let nextLesson = props.sections.find(section => 
      section.id === props.lessonState[nextLessonId].sectionId
    )
    .lessons.find(lesson => 
      lesson.id === nextLessonId
    )
    
    return (
      <div className="button-container">
        <Link to={`/lesson/${nextLesson.id}`} ><button className="action-button">Next Lesson: {nextLesson.name}</button></Link> 
      </div>
    )
  
  } else {
    return (
      <div className="button-container">
        <Link to={`/`} ><button className="action-button">Congratulations, you're done!</button></Link> 
      </div>
    )
  }

}

export default ContinueButton;