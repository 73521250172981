import React from 'react';
import ReactMarkdown from 'react-markdown'
import {Helmet} from 'react-helmet'
import NextUpButton from './NextUpButton'
import AudioPlayer from './AudioPlayer'
import PageNotFound from './Errors.js'
import './Lesson.css'


class Lesson extends React.Component {

  componentDidUpdate() {
    let player = document.getElementById("audioPlayer");
    if (player != null) {
      player.pause();
      player.load();
    }
  }

  render() {
    let lessonId = parseInt(this.props.match.params.lessonId),
        sectionId = null,
        lesson = null;

    try {
      sectionId = this.props.lessonSectionMap[lessonId].sectionId;
      lesson = this.props.sections.find(section => section.id === sectionId).lessons.find(lesson => lesson.id === lessonId);
    } catch (error) {
      return (
        <PageNotFound />
      );
    }

    let lessonIdToCanonicalLinkMapping = {
      17: "https://blog.mywallst.com/what-is-a-stock-split/",
      20: "https://blog.mywallst.com/what-is-short-selling/"  
    }

    var canonicalLink = null;
    if (lesson.id in lessonIdToCanonicalLinkMapping) {
      canonicalLink = <link rel="canonical" href={lessonIdToCanonicalLinkMapping[lesson.id]} />;
    }

    return (
      <div className="content-container">
        <Helmet>
          <title>{lesson.name + " - Learn to Invest with MyWallSt"}</title>
          <meta name="description" content={lesson.description} />
          {canonicalLink}
        </Helmet>
        <ReactMarkdown source={lesson.content} className="markdown-content"/>
        <div>
          <AudioPlayer
            src={"https://d3plmek1bn5sr9.cloudfront.net/" + lesson.audio_url}
          />
        </div>
        <NextUpButton
          lessonId={lessonId}
          sections={this.props.sections}
          lessonSectionMap={this.props.lessonSectionMap}
          handleClick={this.props.handleNextUpButtonClick}
        />
      </div>
    )
  }
}

export default Lesson;
