import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import './App.css';

import Index from './components/Index'
import ContentMenu from './components/ContentMenu'
import Section from './components/Section'
import Lesson from './components/Lesson'
import PageNotFound from './components/Errors'
import { apiResponse } from './apiResponse'
import { arrayToObject } from './util'


class App extends React.Component {
  constructor(props) {
    super(props);
   
    // Mock up Learn API response
    let lessonState = arrayToObject(
      apiResponse.sections.map(section => {
        return section.lessons.map(lesson => {
          return ({
            'lessonId': lesson.id,
            'sectionId': section.id,
            'finished': false
          })
        })
      })
      .flat(),
      'lessonId')

    this.state = {
      'sections': apiResponse.sections,
      'lessonState': lessonState
    }

    this.finishLesson = this.finishLesson.bind(this);
  }

  componentDidMount() {
   this.hydrateLessonStateWithLocalStorage()

    window.addEventListener(
      "beforeunload",
      this.saveLessonStateToLocalStorage.bind(this)
    )
    this.saveLessonStateToLocalStorage = this.saveLessonStateToLocalStorage.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveLessonStateToLocalStorage.bind(this)
    );

    this.saveLessonStateToLocalStorage();
  }

  hydrateLessonStateWithLocalStorage() {
    let key = 'lessonState';
    if (localStorage.hasOwnProperty(key)) {
      let value = localStorage.getItem(key);
      try {
        value = JSON.parse(value);
        this.setState({ [key]: value });
      } catch (e) {  // handle empty string
        this.setState({ [key]: value });
      }
    }
  }

  saveLessonStateToLocalStorage() {
    let key = "lessonState";
    localStorage.setItem(key, JSON.stringify(this.state[key]));
  }

  finishLesson(lessonId) {
    let newLessonState = Object.assign({} , this.state.lessonState);
    newLessonState[lessonId].finished = true;
    this.setState({
      lessonState: newLessonState
    })
  }

  render() {
    return (
    <Router>
        <div className="App container-fill">
          <div className="container-fluid container-fill">
            <div className="row container-fill">
              <div className="col side-menu container-fill">
                <Route
                  exact path={`/lesson/:lessonId`} 
                  render={(routeProps) => (
                    <ContentMenu 
                      {...routeProps} 
                      sections={this.state.sections} 
                      lessonState={this.state.lessonState}
                      showBanner={true}
                    />
                  )}
                />
                <Route 
                  exact path={`/section/:sectionId`} 
                  render={routeProps => (
                    <ContentMenu 
                      {...routeProps} 
                      sections={this.state.sections} 
                      lessonState={this.state.lessonState}
                      showBanner={true}
                    />
                  )} 
                />
                <Route exact path="/" 
                render={routeProps => (
                  <ContentMenu 
                    {...routeProps} 
                    sections={this.state.sections} 
                    lessonState={this.state.lessonState}
                    showBanner={false}
                  />
                )}
                />
              </div>
              <div className="col">
              <Switch>
                <Route 
                  exact path="/" 
                  render={routeProps => (
                    <Index 
                      {...routeProps} 
                      sections={this.state.sections} 
                      lessonState={this.state.lessonState} />
                  )}
                />
                <Route 
                  exact path={`/section/:sectionId`} 
                  render={routeProps => (
                    <Section {...routeProps} sections={this.state.sections} />
                  )} 
                />
                <Route
                  exact path={`/lesson/:lessonId`} 
                  render={routeProps => (
                    <Lesson 
                      {...routeProps} 
                      sections={this.state.sections} 
                      lessonSectionMap={this.state.lessonState}
                      handleNextUpButtonClick={this.finishLesson}
                    />
                  )}
                />
                <Route component={props => { return <PageNotFound /> }} />
              </Switch>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;