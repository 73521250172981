import React from 'react';
import {Helmet} from 'react-helmet'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PageNotFound from './Errors.js'

import './Section.css'

class Section extends React.Component {
  render() {
    let sectionId = parseInt(this.props.match.params.sectionId),
        section = this.props.sections.find(section => section.id === sectionId);;

    if (section === undefined) {
      return (
        <PageNotFound />
      );
    }

    return (
      <div className="content-container section-overview">
        <Helmet>
          <title>{section.name + " - Learn to Invest with MyWallSt"}</title>
          <meta name="description" content={section.subtitle} />
        </Helmet>
        <h1>{section.name}</h1>
        <p>{section.subtitle}</p>
        <br />
        {section.lessons.map((lesson) => {
          return <p key={lesson.id}><Link to={`/lesson/${lesson.id}`}>{lesson.name}</Link></p>;
        })}
      </div>
    );
  }
}

export default Section;