import React from 'react';
import {Helmet} from 'react-helmet'
import ContinueButton from './ContinueButton'
import ProgressBar from './ProgressBar'
import MyWallStBanner from './MyWallStBanner'
import Reviews from './Reviews'
import './Index.css'


const Index = props => {

  return (
    <div className="content-container">
      <Helmet>
        <title>Learn to Invest with MyWallSt</title>
        <meta name="description" content="An investment in knowledge pays the best interest. We teach you to invest with confidence and how you can be successful in the stock market." />
      </Helmet>
      <h1>Learn to Invest with MyWallSt</h1>
      <p>
        MyWallSt is an award-winning company that wants to create a generation of successful investors.
      </p>
      <p>
        With Learn by MyWallSt, you can access 40 investing lessons written by our expert investing team, giving you a clear path towards becoming an investor.
      </p>
      <div className="progress-bar-container">
        <ProgressBar 
          lessonState={props.lessonState} 
        />
      </div>
        <ContinueButton 
          sections={props.sections} 
          lessonState={props.lessonState} 
        />
      <div className="review-container">
        <Reviews/>
      </div>
      <div className="banner-container">
        <MyWallStBanner/>
      </div>
    </div>
  )
}

export default Index;