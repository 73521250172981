import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const ProgressBar = props => {
  var progress = 0;
  for (let lessonId of Object.keys(props.lessonState)) {
    progress += props.lessonState[lessonId].finished;
  }
  let percentageProgress = Math.round( progress / Object.keys(props.lessonState).length * 100 );
  return (
    <CircularProgressbar 
      value={percentageProgress} 
      text={`${percentageProgress}% complete`}
      styles={buildStyles({
        textSize: '12px',
        textColor: '#0d4c5a',
        pathColor: '#F5A73F',
        trailColor: '#EEEAE0'
      })}
    />
  )
}

export default ProgressBar;