import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './ContentMenu.css';
import logo from '../assets/images/learn-logo.svg'

import StoreButtons from './StoreButtons'


class ContentMenu extends React.Component { 

  constructor(props) {
    super(props);

    this.selectedSection = this.selectedSection.bind(this)
  }

  selectedSection() {
      const lessonId = parseInt(this.props.match.params.lessonId)
      const sectionId = parseInt(this.props.match.params.sectionId)
      
      if (lessonId) {
        for (const [index, section] of this.props.sections.entries()) {
          for (const [il, lesson] of section.lessons.entries()) {
            if (lesson.id === lessonId) {
              return section.id
            }
          }
        }
      } else if (sectionId) {
          return sectionId
      }
  }

  render() {

    const selectedSectionId = this.selectedSection()
    const selectedLessonId = parseInt(this.props.match.params.lessonId)

    let sectionsContent = this.props.sections.map((section) => {

      let lessons = ""

      if (section.id === selectedSectionId) {
        lessons = section.lessons.map((lesson) => {

          let finishedMarker = <span className={this.props.lessonState[lesson.id].finished ? 'finished-dot' : 'unfinished-dot'}></span>

          return (
            <p className="section-item" id={lesson.id === selectedLessonId ? "active-lesson" : "inactive-lesson"} key={lesson.id}><Link to={`/lesson/${lesson.id}/`}><button>{lesson.name} {finishedMarker}</button></Link></p>
          )
        })
      } else {
         lessons = ""
      }
      

      return (
        <div key={section.id}>

          <h2 className={"section-header" + (section.id === selectedSectionId ? " active" : " inactive")}><Link to={`/section/${section.id}`}><button>{section.name}</button></Link></h2>
          
          {lessons}
        </div>
      )
    })
    

    return (
      <div className="sidebar-sticky menu-content">
        <Link to="/"><img src={logo} alt="logo" id="logo" /></Link>
        {sectionsContent}
        { this.props.showBanner &&
          <div className="store-links">
            <div><p>Ready to invest?<br/>Download the MyWallSt app.</p></div>
            <StoreButtons/>
          </div>
        }
      </div>
    );
  }
}

export default ContentMenu;