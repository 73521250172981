import React from 'react';
import {Helmet} from 'react-helmet'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './Errors.css';

const PageNotFound = props => {
  return (
    <div className="container-fluid" id="page-not-found-container">
      <Helmet>
        <title>Learn to Invest with MyWallSt</title>
        <meta name="description" content="An investment in knowledge pays the best interest. We teach you to invest with confidence and how you can be successful in the stock market." />
      </Helmet>
      <div className="error-template">
        <h1>
          Oops!
        </h1>
        <h2>
          404 Not Found
        </h2>
        <div className="error-details">
          The requested page doesn't exist.
        </div>
        <div className="error-actions background">
          <a href="/" className="btn btn-primary btn-lg action-button">
            Take Me Home
          </a>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound;