import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import StoreButtons from './StoreButtons'

import app_screenshot from '../assets/images/mywallst_ios_03.jpg'

import './MyWallStBanner.css'

const MyWallStBanner = props => {
  return (
    <div className="container-fluid" id="banner-wrapper">
      <div id="bg-container">
        <div className="row">
          <div className="col-sm-4" id="image-wrapper">
            <div id="image-container">
              <img alt="MyWallSt app" src={app_screenshot}/>
            </div>
          </div>
          <div className="col-sm-8 right-column">
            <div className="row" id="text-wrapper">
              <div className="col-sm-12">
                <p className="font_8">
                  Ready to invest? Discover our shortlist of hand-picked stocks in the MyWallSt app. Your one-stop solution to beat the market.
                </p>
              </div>
            </div>
            <div className="row" id="button-wrapper">
              <StoreButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyWallStBanner;