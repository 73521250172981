import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './AudioPlayer.css'

const AudioPlayer = props => {
  return (
    <div className="player-container"> 
      <audio id="audioPlayer" controls>
        <source src={props.src} type="audio/mpeg" />
      </audio> 
    </div>
  )
}

export default AudioPlayer;